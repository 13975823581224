import {
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
  type ReactNode,
  type MutableRefObject,
} from 'react'

import { Icon } from '@cais-group/equity/atoms/icon'
import { useCloseOnEsc } from '@cais-group/shared/util/hook/use-close-on-esc'

import { MenuRoot } from './shared/menu-root'
import * as styles from './shared/styles'
import { useFocusOut } from './shared/use-focus-out'
import { type NavProps } from './types'

/**
 * Provides a wrapper for mobile menus.
 * Used by the primary nav and Profile menu.
 */
type MobileMenuContainerProps = PropsWithChildren<
  NavProps & {
    id: string
    label: string
    menu?: ReactNode
  }
>

function MenuButton({ name }: { name: string }) {
  return (
    <>
      {name}
      <span
        className={`flex origin-center group-aria-expanded:rotate-90 ${styles.primary.expandIcon}`}
      >
        <Icon type="KeyboardArrowRight" />
      </span>
    </>
  )
}

export function MobileMenuContainer(props: MobileMenuContainerProps) {
  const { children, label, ...otherProps } = props
  const buttonRef = useRef<HTMLButtonElement>(null)
  const containerRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLDivElement>

  // The parent not the submenu e.g. the hamburger menu
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false)

  useEffect(() => {
    const handleNavItemClick = (e: PointerEvent | MouseEvent) => {
      const el = e.target as HTMLElement
      if (el.tagName === 'A') {
        setIsNavMenuOpen(false) // TODO EQL-249 - this is triggering a re-render
        focusOnElement()
      }
    }
    const containerRefCurrent = containerRef.current
    containerRefCurrent?.addEventListener('click', handleNavItemClick)

    return () => {
      containerRefCurrent &&
        containerRefCurrent.removeEventListener('click', handleNavItemClick)
    }
  }, [])

  useCloseOnEsc(() => {
    if (isNavMenuOpen) {
      setIsNavMenuOpen(false)
      focusOnElement()
    }
  })

  useFocusOut(containerRef, () => setIsNavMenuOpen(false))

  // This is for the Hamburger menu
  const toggleNavMenu = () => {
    setIsNavMenuOpen((o) => !o)
  }

  const focusOnElement = () => {
    if (buttonRef?.current) {
      buttonRef.current?.focus({
        preventScroll: true,
      })
    }
  }

  return (
    <div
      ref={containerRef}
      className={`bg-neutral-0 z-30 has-[#profile-menu]:md:hidden ${styles.wideScreenBreakpoint.display.hidden}`}
    >
      <button
        ref={buttonRef}
        type="button"
        className="*:ease-standard *:duration-short-3 grid place-items-center *:flex *:transition-opacity *:[grid-area:1/1] focus-visible:ring"
        onClick={toggleNavMenu}
        aria-expanded={isNavMenuOpen}
        aria-controls={props.id}
        aria-label={label}
        data-global-nav-mobile-menu // used to inform the body tag that the menu is open and it should apply fixed positioning
      >
        <span className={isNavMenuOpen ? 'opacity-100' : 'opacity-0'}>
          <Icon type="Close" />
        </span>
        <span className={isNavMenuOpen ? 'opacity-0' : 'opacity-100'}>
          {children}
        </span>
      </button>

      <MenuRoot
        {...otherProps}
        classNames={{
          list: `${isNavMenuOpen ? 'visible' : 'hidden'} ${
            styles.primary.activeMenuItem
          } bg-neutral-0 absolute bottom-0 left-0 right-0 top-[theme(constants.mainMenu.height)px] h-screen space-y-32 overflow-y-auto p-32 pl-24`,
          listItem: 'last:pb-[theme(constants.mainMenu.height)px]', // ensure the last menu isn't cut off
          menu: 'headline-s flex w-full items-center justify-between p-0 outline-none ring-offset-4',
          menuItem: `${styles.animateExpandCollapse} aria-expanded:pt-16 aria-expanded:visible aria-expanded:opacity-100 invisible opacity-0`,
        }}
        menuButton={MenuButton}
        type="mobile"
      />
    </div>
  )
}
