import { linkToCaisIq } from './link-to-cais-iq'
import { Menu } from './types'

export const AnalyticsMenu: Menu = {
  id: 'analytics-menu',
  groups: [
    {
      items: [
        {
          name: 'CAIS IQ Dashboard',
          href: linkToCaisIq('/authenticated/homeoffice').toString(),
          permissions: [
            'caisiq:reporting:read',
            'portal:ROLE_CAISIQ_REPORTING',
          ],
          urlType: 'absolute',
        },
        {
          name: 'Home Office Dashboard',
          href: '/home-office-insights',
          permissions: ['&home-office-insights:read'],
        },
        {
          name: 'Manager Dashboard',
          href: '/fund-manager',
          permissions: ['&insights:read'],
        },
        {
          name: 'Reports',
          href: '/platform/reports',
          features: ['PORTAL_REPORTS'],
        },
        {
          name: 'CAIS Compass',
          href: '/cais-compass',
          permissions: ['portfolios:custom:read'],
        },
      ],
    },
  ],
}

export const HelpMenu: Menu = {
  id: 'help-menu',
  groups: [
    {
      items: [
        {
          name: 'Tutorials',
          href: '/homepage/help/tutorials',
          personas: ['!FUND_MANAGER', '@NOT_EMPTY'],
        },
        {
          name: 'Glossary',
          href: '/homepage/help/glossary',
          personas: ['!FUND_MANAGER', '@NOT_EMPTY'],
        },
        {
          name: 'FAQ',
          href: '/homepage/help/faqs',
          personas: ['!FUND_MANAGER', '@NOT_EMPTY'],
        },
      ],
    },
  ],
}

export const HoldingsMenu: Menu = {
  id: 'holdings-menu',
  groups: [
    {
      items: [
        {
          name: 'Alternatives',
          href: '/cais-accounts/holdings/hedge-funds/positions/cais',
          permissions: ['portal:view-cais-accounts'],
        },
        {
          name: 'Structured Investments',
          href: '/structured-investments/holdings',
          permissions: ['structured-products:read'],
        },
        {
          name: 'Documents',
          href: '/document-library/',
          permissions: [
            'documents:read:all',
            'documents:read:firm',
            'documents:read:team',
            'documents:read:user',
            'pipelines:documents:read',
            'pipelines:documents:rerun',
          ],
        },
        {
          name: 'Client Snapshot',
          href: '/snapshot/',
          features: ['PORTAL_DASHBOARD'],
        },
      ],
    },
  ],
}

export const InsightsMenu: Menu = {
  id: 'insights-menu',
  groups: [
    {
      items: [
        {
          name: 'Events',
          href: '/homepage/events',
          personas: ['!FUND_MANAGER', '@NOT_EMPTY'],
        },
        {
          name: 'Research',
          href: '/homepage/research',
          personas: ['!FUND_MANAGER', '@NOT_EMPTY'],
        },
        {
          name: 'Structured Investments',
          href: '/homepage/resources/structured-investments',
          personas: ['!FUND_MANAGER', '@NOT_EMPTY'],
          permissions: ['&structured-products:current-offerings:read'],
          capabilities: ['&STRUCTURED_PRODUCTS'],
        },
      ],
    },
  ],
}

export const ManageCAISMenu: Menu = {
  id: 'manage-cais-menu',
  groups: [
    {
      items: [
        {
          name: 'Alternatives',
          href: '/funds-product-master',
          permissions: ['funds:products:admin-read'],
        },
        {
          name: 'Structured Investments',
          href: '/product-master',
          permissions: ['structured-products:write'],
        },
        {
          name: 'CAIS IQ',
          href: linkToCaisIq('/authenticated/manage/experiences').toString(),
          permissions: ['&portal:ROLE_CAISIQ_ADMIN'],
          urlType: 'absolute',
        },
        {
          name: 'Firms & Users',
          href: '/access-manager',
          permissions: ['iam:users:list:all', 'iam:firms:list:all'],
        },
        {
          name: 'Custodians',
          href: '/custodian-manager',
          permissions: ['custodians:read', 'custodians:list'],
        },
        {
          name: 'Systems Manager',
          href: '/systems-manager',
          permissions: ['communications:list:all'],
        },
        {
          name: 'Funds Orders',
          href: '/funds-trade/active-orders',
          permissions: ['funds:orders:read'],
        },
        {
          name: 'Feature Flags',
          href: '/access-manager/feature-flags',
          permissions: ['cais:feature-flags:read'],
        },
        {
          name: 'Funds Investments (ADS)',
          href: '/ads',
          permissions: ['files:ads:read:all'],
        },
      ],
    },
    {
      name: 'Classic Platform',
      items: [
        {
          name: 'Sales',
          href: '/manageCAISX#sales',
          features: ['PORTAL_MANAGE_CAIS'],
        },
        {
          name: 'Marketing',
          href: '/manageCAISX#marketing',
          features: ['PORTAL_MANAGE_CAIS'],
        },
        {
          name: 'Operations',
          href: '/manageCAISX#operations',
          features: ['PORTAL_MANAGE_CAIS'],
        },
        {
          name: 'Legal',
          href: '/internal/eta/newrequests',
          features: ['PORTAL_MANAGE_CAIS'],
        },
        {
          name: 'Investment Solutions',
          href: '/manageCAISX#investmentSolutions',
          features: ['PORTAL_MANAGE_CAIS'],
        },
        {
          name: 'Analytics',
          href: '/internal/analytics',
          features: ['PORTAL_MANAGE_CAIS'],
        },
        {
          name: 'Data Quality',
          href: '/internal/dataquality',
          features: ['PORTAL_MANAGE_CAIS'],
        },
      ],
    },
  ],
}

export const OfferingsMenu: Menu = {
  id: 'offerings-menu',
  groups: [
    {
      items: [
        {
          name: 'Alternatives',
          href: '/funds-pre-trade',
          permissions: ['funds:products:advisor-read'],
        },
        {
          name: 'Structured Investments',
          href: '/structured-investments/current-offerings',
          permissions: ['structured-products:current-offerings:read'],
        },
        {
          name: 'Structured Investments SMAs',
          href: '/homepage/structured-investments/structured-investments-smas',
          permissions: ['&structured-products:current-offerings:read'],
          capabilities: [
            '&STRUCTURED_PRODUCTS',
            '&STRUCTURED_PRODUCTS_MARINER_SMAS',
          ],
        },
        {
          name: 'Hedging and Monetization Strategies',
          href: '/homepage/structured-investments/hedging-and-monetization-strategies',
          permissions: ['&structured-products:current-offerings:read'],
          capabilities: [
            '&STRUCTURED_PRODUCTS',
            '&STRUCTURED_PRODUCTS_HEDGE_MONETIZATION',
          ],
        },
      ],
    },
    {
      name: 'Focus Financial',
      items: [
        {
          name: 'Private Strategies',
          href: '/funds-pre-trade/focus-private-strategies',
          permissions: ['focus-public-strategies:read'],
        },
        {
          name: 'Public Strategies',
          href: '/focus-public-strategies',
          permissions: ['focus-public-strategies:read'],
        },
      ],
    },
  ],
}

export const InvestmentsMenu: Menu = {
  id: 'investments-menu',
  groups: [
    {
      items: [
        {
          name: 'Alternatives',
          href: '/funds-pre-trade',
          permissions: ['funds:products:advisor-read'],
        },
        {
          name: 'Structured Investments',
          href: '/structured-investments/current-offerings',
          permissions: ['structured-products:current-offerings:read'],
        },
        {
          name: 'Structured Investments SMAs',
          href: '/homepage/structured-investments/structured-investments-smas',
          permissions: ['&structured-products:current-offerings:read'],
          capabilities: [
            '&STRUCTURED_PRODUCTS',
            '&STRUCTURED_PRODUCTS_MARINER_SMAS',
          ],
        },
        {
          name: 'Hedging and Monetization Strategies',
          href: '/homepage/structured-investments/hedging-and-monetization-strategies',
          permissions: ['&structured-products:current-offerings:read'],
          capabilities: [
            '&STRUCTURED_PRODUCTS',
            '&STRUCTURED_PRODUCTS_HEDGE_MONETIZATION',
          ],
        },
      ],
    },
    {
      name: 'Focus Financial',
      items: [
        {
          name: 'Private Strategies',
          href: '/funds-pre-trade/focus-private-strategies',
          permissions: ['focus-public-strategies:read'],
        },
        {
          name: 'Public Strategies',
          href: '/focus-public-strategies',
          permissions: ['focus-public-strategies:read'],
        },
      ],
    },
  ],
}

export const OrdersMenu: Menu = {
  id: 'orders-menu',
  groups: [
    {
      items: [
        {
          name: 'Alternatives',
          href: '/investment-pipeline/ai/grid/5',
          features: [
            'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_USER',
            'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_ADMIN',
          ],
          permissions: [
            'portal:view-pipeline',
            'portal:view-manage-transaction-details',
            'portal:generate-recommendations',
          ],
        },
        {
          name: 'Structured Investments',
          href: '/structured-investments/advisor/order-management-tool',
          permissions: [
            'structured-products:orders:advisors:read',
            'structured-products:orders:advisors:write',
          ],
        },
        {
          name: 'Structured Investments (Internal)',
          href: '/structured-investments/sales/order-management-tool',
          permissions: [
            'structured-products:orders:sales:read',
            'structured-products:orders:sales:write',
          ],
        },
      ],
    },
  ],
}
