import { type ReactNode } from 'react'

import { MainNav as MainNavEQL249 } from './shared-ui-main-nav-eql-249'
import { MainNav as MainNavOriginal } from './shared-ui-main-nav-original'
import { NavItem, Profile } from './types'

type GlobalNavProps = {
  navItems: NavItem[]
  profile: Profile
  logos?: ReactNode[]
  logoHref?: string
  isExternalNav?: boolean
  isContainerNav?: boolean
  enabledFeatureFlags?: string[]
}

export function MainNav(props: GlobalNavProps) {
  const { enabledFeatureFlags = [] } = props

  if (enabledFeatureFlags.includes('eql-249')) {
    return <MainNavEQL249 {...props} />
  }
  return <MainNavOriginal {...props} />
}

export default MainNav
