import { type NavItemMenu } from '@cais-group/shared/util/entitled-nav-links'

type QuickLinksProps = {
  enableModelPortfoliosLinks?: boolean
}

export const defaultQuickLinks = (props?: QuickLinksProps): NavItemMenu[] => [
  {
    name: 'Quick Links',
    menu: {
      id: 'quick-links',
      groups: props?.enableModelPortfoliosLinks
        ? [compassLinks, ...defaultGroups]
        : defaultGroups,
    },
  },
]

const compassLinks: NavItemMenu['menu']['groups'][0] = {
  items: [
    {
      name: 'CAIS Model Portfolios',
      href: '/model-portfolios',
      icon: 'PieChart',
      permissions: ['portfolios:model:read'],
      status: 'New',
    },
    {
      name: 'CAIS Compass',
      href: '/cais-compass',
      icon: 'Explore',
      permissions: ['portfolios:custom:read'],
      status: 'New',
    },
  ],
}

const defaultGroups: NavItemMenu['menu']['groups'] = [
  {
    name: 'Alternative Investments',
    items: [
      {
        name: 'Discover Offerings',
        href: '/funds-pre-trade/funds',
        icon: 'Product',
        permissions: ['funds:products:advisor-read'],
      },
      {
        name: 'Manage Orders',
        href: '/investment-pipeline/ai/grid/5',
        icon: 'Build',
        features: [
          'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_USER',
          'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_ADMIN',
        ],
        permissions: [
          'portal:view-pipeline',
          'portal:view-manage-transaction-details',
          'portal:generate-recommendations',
        ],
      },
      {
        name: 'View Holdings',
        href: '/cais-accounts/holdings/hedge-funds/positions/cais',
        icon: 'Map',
        permissions: ['portal:view-cais-accounts'],
      },
    ],
  },
  {
    name: 'Structured Investments',
    items: [
      {
        name: 'Discover Offerings',
        href: '/structured-investments/current-offerings',
        icon: 'Product',
        permissions: ['structured-products:current-offerings:read'],
      },
      {
        name: 'Manage Orders',
        href: '/structured-investments/advisor/order-management-tool',
        icon: 'Build',
        permissions: [
          'structured-products:orders:advisors:read',
          'structured-products:orders:advisors:write',
        ],
      },
      {
        name: 'View Holdings',
        href: '/structured-investments/holdings/summary',
        icon: 'Map',
        permissions: ['structured-products:read'],
      },
    ],
  },
]
