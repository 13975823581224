import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'

import { UserProfileV1 } from '@cais-group/access-manager/domain/api'
import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { Wave } from '@cais-group/equity/atoms/wave'
import { Footer } from '@cais-group/equity/molecules/footer'
import { useModal } from '@cais-group/equity/organisms/modal'
import { useGetTermsAndConditionsLegalPageBySlug } from '@cais-group/shared/domain/contentful/legal'
import { authService } from '@cais-group/shared/util/auth-service'
import { getTermsAndConditionsToAccept } from '@cais-group/shared/util/get-terms-and-conditions-to-accept'
import { TermsAndConditionType } from '@cais-group/shared/util/graphql'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'

import { TermsAndConditionsConfirmDeclineModal } from './terms-and-conditions-confirm-decline-modal'
import { TermsAndConditionsModal } from './terms-and-conditions-modal'

const CURRENT_TERMS_CONDITIONS_VERSION = 3

const TERMS_AND_CONDITIONS_PAGE_SLUG = 'terms-and-conditions-of-use'

export const shouldShowTerms = (userProfile: UserProfileV1 | null) => {
  if (!userProfile || __NX_DEVELOPMENT__) {
    return false
  }

  const hasTermsToAccept = !!getTermsAndConditionsToAccept(
    userProfile.termsAndConditions,
    CURRENT_TERMS_CONDITIONS_VERSION,
    TermsAndConditionType.Platform
  )

  if (!hasTermsToAccept || userProfile?.isSwitched) {
    return false
  }

  return true
}

export function TermsAndConditionsIndex() {
  const {
    userProfileState: { userProfile, acceptingTermsStatus },
    acceptTerms,
  } = useUserProfile()
  const { logout: auth0Logout } = useAuth0()
  const termsModal = useModal()
  const confirmModal = useModal()
  const { pageData } = useGetTermsAndConditionsLegalPageBySlug({
    slug: TERMS_AND_CONDITIONS_PAGE_SLUG,
  })

  const handleAccept = async () => {
    const terms = getTermsAndConditionsToAccept(
      userProfile?.termsAndConditions,
      3,
      TermsAndConditionType.Platform
    )

    if (!terms || !terms.type) {
      throw Error(
        'Terms not available for user to accept. This should never happen.'
      )
    }

    await acceptTerms({
      type: terms.type,
      version: terms.version,
    })
    termsModal.closeModal()
  }

  const handleDeclineTerms = () => {
    termsModal.closeModal()
    if (!confirmModal.isOpen) {
      confirmModal.openModal()
    }
  }

  const handleConfirmDeclining = useCallback(() => {
    if (!authService.getIsValidSession()) {
      return
    }
    authService.invalidateSession()

    const appBasePath = window.location.pathname.split('/')[1]
    auth0Logout({
      logoutParams: {
        returnTo: __NX_DEVELOPMENT__
          ? `${window.location.origin}/${appBasePath}`
          : `${window.location.origin}/auth-redirect?app=${appBasePath}`,
      },
    })
  }, [auth0Logout])

  const handleCancelDeclining = () => {
    confirmModal.closeModal()
    if (!termsModal.isOpen) {
      termsModal.openModal()
    }
  }

  useEffect(() => {
    if (userProfile) {
      try {
        const terms = getTermsAndConditionsToAccept(
          userProfile.termsAndConditions,
          3,
          TermsAndConditionType.Platform
        )
        if (terms && !confirmModal.isOpen && !termsModal.isOpen) {
          termsModal.openModal()
        }
      } catch (e) {
        return
      }
    }
  }, [confirmModal.isOpen, termsModal, userProfile])

  if (!shouldShowTerms(userProfile)) {
    return null
  }

  if (acceptingTermsStatus === 'accepting') {
    return (
      <LoadingContainer
        state={LoadingState.LOADING}
        coverPage="FULL_SCREEN_WITH_HEADER"
        type="small"
      />
    )
  }

  return (
    <div className="bg-gradient-3-1 from-gradient-3-1 to-gradient-3-2 flex min-h-[100vh] flex-col justify-between bg-gradient-to-r">
      <main className="[&>dialog:first-of-type]:bottom-[theme(constants.mainMenu.height)px] [&>dialog>div:first-of-type]:max-h-[80vh]">
        <TermsAndConditionsModal
          onAccept={handleAccept}
          onDecline={handleDeclineTerms}
          modal={termsModal}
          pdf={pageData}
        />

        <TermsAndConditionsConfirmDeclineModal
          modal={confirmModal}
          onConfirm={handleConfirmDeclining}
          onCancel={handleCancelDeclining}
        />
      </main>
      <div>
        <Footer />
        <div className="absolute bottom-[theme(constants.mainMenu.height)px] left-0 right-0">
          <Wave preset="hero-gradient-dark" />
        </div>
      </div>
    </div>
  )
}
