// The HACK! enjoy :) SP doesn't want to change their app name, so...
const getAppBasePath = (path: string) =>
  path === 'structured-investments' ? 'structured-products' : path

export function getAppEnvConfigPath() {
  if (typeof globalThis.location === 'undefined') {
    return '/env.json'
  }

  const appPath = globalThis.location.pathname.split('/')[1]
  return `/${getAppBasePath(appPath)}/env.json`
}
