import { Icon } from '@cais-group/equity/atoms/icon'

import { MenuRoot } from './shared/menu-root'
import * as styles from './shared/styles'
import { type NavProps } from './types'

function MenuButton({ name }: { name: string }) {
  return (
    <span
      className={`${styles.primary.expandIcon} body flex items-center`}
      data-pendo={`${name.toLowerCase()}-menu`}
    >
      {name}
      <Icon type="ArrowDropDown" />
    </span>
  )
}

export function DesktopNav(props: NavProps) {
  return (
    <MenuRoot
      {...props}
      classNames={{
        list: `z-20 hidden flex-grow items-center gap-x-16 [&>li]:relative [&>li]:inline-block ${styles.wideScreenBreakpoint.display.flex} ${styles.primary.activeMenuItem}`,
        listItem: 'group',
        menu: 'z-50',
        menuItem: styles.primary.menuItem,
      }}
      menuButton={MenuButton}
      type="desktop"
    />
  )
}
