import { useEffect, useRef, type MutableRefObject } from 'react'

import { useCloseOnEsc } from '@cais-group/shared/util/hook/use-close-on-esc'

import { useFocusOut } from './use-focus-out'

/**
 * Keyboard controls
 * ------------
 * tab to item
 * enter/return to open/close
 * arrow keys to navigate submenu + tab?
 * tab to continue to next parent item
 * esc to close submenu
 */

type KeyboardNavProps = {
  handleClose: (options?: { refocus?: boolean }) => void
  isMenuOpen: boolean
  menuRef: MutableRefObject<HTMLDivElement | HTMLLIElement>
}

type MenuItems = NodeListOf<HTMLAnchorElement> | null

export function useKeyboardMenuNavigation(props: KeyboardNavProps) {
  const INITIAL_FOCUS_INDEX = -1
  const { handleClose, isMenuOpen, menuRef } = props
  const focusIndex = useRef<number>(INITIAL_FOCUS_INDEX)
  const menuItems = useRef<MenuItems>() as MutableRefObject<MenuItems>

  useCloseOnEsc(() => {
    if (menuItems.current) {
      handleClose()
      return
    }
  })

  useFocusOut(menuRef, () => handleClose({ refocus: false }))

  useEffect(() => {
    if (isMenuOpen) {
      const handleKeyDown = (event: Event | KeyboardEvent) => {
        if (!menuItems.current) return

        if (!('key' in event)) return

        if (event.key === 'ArrowDown') {
          event.preventDefault()
          // Focus first item, if we were at the last item
          if (focusIndex.current === menuItems.current.length - 1) {
            focusIndex.current = 0
            menuItems.current[0].focus()
          } else {
            // Focus the next item
            focusIndex.current++
            menuItems.current[focusIndex.current].focus()
          }
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault()
          // Focus the last item if we are at the first item
          if (focusIndex.current === 0) {
            menuItems.current[menuItems.current.length - 1].focus()
            focusIndex.current = menuItems.current.length - 1
          } else {
            // Focus previous item
            focusIndex.current--
            menuItems.current[focusIndex.current].focus()
          }
        }

        // Update the focusIndex value but let the browser handle the actual focus
        // Ensures that combining tab and arrow keys works as expected
        if (event.key === 'Tab' && event.shiftKey) {
          if (focusIndex.current === 0) {
            focusIndex.current = menuItems.current.length - 1
          } else {
            focusIndex.current--
          }
        }

        if (event.key === 'Tab' && !event.shiftKey) {
          if (focusIndex.current === menuItems.current.length - 1) {
            focusIndex.current = 0
          } else {
            focusIndex.current++
          }
        }
      }

      const menuRefCurrent = menuRef.current
      menuItems.current = menuRef.current.querySelectorAll('a')
      menuRefCurrent.addEventListener('keydown', handleKeyDown)

      return () => {
        menuItems.current = null
        focusIndex.current = INITIAL_FOCUS_INDEX // Reset focusIndex to first element so when the menu opens again we can focus it
        menuRefCurrent.removeEventListener('keydown', handleKeyDown)
      }
    }
    return
  }, [isMenuOpen]) // eslint-disable-line react-hooks/exhaustive-deps
}
