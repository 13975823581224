import {
  CAROUSEL_ITEM_LIMIT,
  LATEST_BY_FIRM_MINIMUM,
} from '@cais-group/shared/util/constants'
import { ContentDataType } from '@cais-group/shared/util/contentful/types'

/**
 *
 * @param data Selects one of each item from the data array until the carousel item limit is reached
 * @returns An array of content items
 */
export const orderLatestContent = (
  data: ContentDataType[][]
): ContentDataType[] => {
  const latest: ContentDataType[] = []
  let i = 0

  // The limit prevents a potential infinite loop if for some reason the user
  // doesn't have enough items to fill the carousel
  const maxAvailableToUser = data.reduce((acc, curr) => acc + curr.length, 0)

  while (
    (latest.length < CAROUSEL_ITEM_LIMIT &&
      latest.length < maxAvailableToUser) ||
    (i < maxAvailableToUser && latest.length < CAROUSEL_ITEM_LIMIT)
  ) {
    const currentLatest = data.reduce((acc, curr) => {
      const item = i < curr.length ? curr[i] : null

      if (item) {
        acc.push(item)
      }

      return acc
    }, [])

    latest.push(...currentLatest)
    i++
  }

  return latest
}

export const selectLatestContent = (
  data: ContentDataType[][],
  wait: boolean
) => {
  if (wait) {
    return {
      latestByFirm: [],
      latestByCais: [],
    }
  }
  const { latestByFirm, latestByCais } = data.reduce<{
    latestByFirm: ContentDataType[][]
    latestByCais: ContentDataType[][]
  }>(
    (acc, subArray) => {
      const { filteredByFirmSubArray, filteredByCaisSubArray } =
        subArray.reduce<{
          filteredByFirmSubArray: ContentDataType[]
          filteredByCaisSubArray: ContentDataType[]
        }>(
          (innerAcc, item) => {
            if (
              item.byCais === false &&
              Boolean(item.firmsCollection?.items.length)
            ) {
              innerAcc.filteredByFirmSubArray.push(item)
            } else if (item.byCais !== false) {
              innerAcc.filteredByCaisSubArray.push(item)
            }
            return innerAcc
          },
          { filteredByFirmSubArray: [], filteredByCaisSubArray: [] }
        )

      if (filteredByFirmSubArray.length > 0) {
        acc.latestByFirm.push(filteredByFirmSubArray)
      }
      if (filteredByCaisSubArray.length > 0) {
        acc.latestByCais.push(filteredByCaisSubArray)
      }

      return acc
    },
    { latestByFirm: [], latestByCais: [] }
  )
  // Note: exclude latestByFirm if 4 or less items are available since these will be shown in the curated "Your Resources" section.
  const latestByFirmOrdered = orderLatestContent(latestByFirm)
  return {
    latestByFirm:
      latestByFirmOrdered.length >= LATEST_BY_FIRM_MINIMUM
        ? latestByFirmOrdered
        : [],
    latestByCais: orderLatestContent(latestByCais),
  }
}
