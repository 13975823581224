export const FIRMS_COLLECTION_LIMIT = 1
export const TAGS_COLLECTION_LIMIT = 10
export const DEFAULT_CMS_CURATED_FIRM_UUID =
  '00000000-0000-0000-0000-000000000000'
export const CONTENT_OWNERSHIP_DISCLAIMER_QUERY_SLUG =
  'content-ownership-disclaimer'
export const CAROUSEL_ITEM_LIMIT = 12
export const LATEST_BY_FIRM_MINIMUM = 5

export const FEATURED_CONTENT_APP_ID = 'homepage'
export const PRESS_RELEASE_INDEX = '/our-company/press/'

export const PLACEHOLDER_SHORT_FIRM_NAME = /{shortFirmNames}/g
export const PLACEHOLDER_LONG_FIRM_NAME = /{longFirmNames}/g
export const PLACEHOLDER_USERS_FIRM_NAME = /{usersFirmName}/g

export const FILTERS = {
  research: {
    is: {
      category: 'research',
    },
  },
  tutorials: {
    is: {
      category: 'tutorials',
    },
    isNot: {
      OR: [{ category_exists: false }, { category_not: 'tutorials' }],
    },
  },
  webinars: {
    is: {
      category: 'webinars',
    },
  },
  inPersonEvents: {
    is: {
      category: 'in-person-events',
    },
  },
  inPersonSummitEvents: {
    is: {
      category: 'research',
    },
  },
}
