import { Icon } from '@cais-group/equity/atoms/icon'

import { MobileMenuContainer } from './mobile-menu-container'
import { type NavProps } from './types'

export const MobileNav = (props: NavProps) => {
  return (
    <MobileMenuContainer
      id="primary-nav-mobile"
      label="Primary navigation"
      {...props}
    >
      <Icon type="HamburgerMenu" />
    </MobileMenuContainer>
  )
}
