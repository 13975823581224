import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AddIcon from '@mui/icons-material/Add'
import ApartmentIcon from '@mui/icons-material/Apartment'
import AppsIcon from '@mui/icons-material/Apps'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined'
import BuildIcon from '@mui/icons-material/Build'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DescriptionIcon from '@mui/icons-material/Description'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import EventIcon from '@mui/icons-material/Event'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ExploreIcon from '@mui/icons-material/Explore'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FilterListIcon from '@mui/icons-material/FilterList'
import FlagOutlined from '@mui/icons-material/FlagOutlined'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import GppGoodIcon from '@mui/icons-material/GppGood'
import GroupsIcon from '@mui/icons-material/Groups'
import HomeIcon from '@mui/icons-material/Home'
import ImageIcon from '@mui/icons-material/Image'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LaunchIcon from '@mui/icons-material/Launch'
import LayersIcon from '@mui/icons-material/Layers'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import LinkedIn from '@mui/icons-material/LinkedIn'
import LockIcon from '@mui/icons-material/Lock'
import MapIcon from '@mui/icons-material/Map'
import MenuIcon from '@mui/icons-material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PieChartIcon from '@mui/icons-material/PieChart'
import RefreshIcon from '@mui/icons-material/Refresh'
import RemoveIcon from '@mui/icons-material/Remove'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import ShareIcon from '@mui/icons-material/Share'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import SortIcon from '@mui/icons-material/Sort'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import SyncIcon from '@mui/icons-material/Sync'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import TopicIcon from '@mui/icons-material/Topic'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import ViewListIcon from '@mui/icons-material/ViewList'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import WarningIcon from '@mui/icons-material/Warning'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import SvgIcon from '@mui/material/SvgIcon'

import { IconType } from '../index'

import { ReactComponent as CollapseIcon } from './assets/Collapse.svg'
import { ReactComponent as CommentIcon } from './assets/Comment.svg'
import { ReactComponent as ExpandIcon } from './assets/Expand.svg'
import { ReactComponent as SortByAlphaIcon } from './assets/SortByAlpha.svg'
import { ReactComponent as SortByAlphaReverseIcon } from './assets/SortByAlphaReverse.svg'
import { ReactComponent as SortByDateIcon } from './assets/SortByDate.svg'
import { ReactComponent as SortByDateReverseIcon } from './assets/SortByDateReverse.svg'
import { ReactComponent as SortLowToHighIcon } from './assets/SortLowToHigh.svg'

type MuiElement = typeof SvgIcon | typeof CommentIcon
type MuiName = string | null
type Icon = [EquityName: string, MuiElement, MuiName]

export const iconsMapping: Record<IconType, Icon> = {
  Add: ['Add', AddIcon, 'Add'],
  AddToLibrary: ['AddToLibrary', LibraryAddIcon, 'LibraryAdd'],
  Apps: ['Apps', AppsIcon, 'Apps'],
  ArrowBackLrg: ['Arrow back lrg', ArrowBackIosNewIcon, 'ArrowBackIosNew'],
  ArrowDown: ['Arrow down', ArrowDownwardIcon, 'ArrowDownwardIcon'],
  ArrowDropDown: ['Arrow drop down', ArrowDropDownIcon, 'ArrowDropDown'],
  ArrowDropUp: ['Arrow drop up', ArrowDropUpIcon, 'ArrowDropUp'],
  ArrowForwardLrg: [
    'Arrow forward lrg',
    ArrowForwardIosIcon,
    'ArrowForwardIos',
  ],
  ArrowLeft: ['Arrow left', ArrowBackIcon, 'ArrowBack'],
  ArrowRight: ['Arrow right', ArrowForwardIcon, 'ArrowForward'],
  ArrowUp: ['Arrow up', ArrowUpwardIcon, 'ArrowUpwardIcon'],
  BarChart: ['BarChart', SignalCellularAltIcon, 'SignalCellularAlt'],
  Bookmark: ['Bookmark', BookmarkIcon, 'Bookmark'],
  BookmarkOutline: ['Bookmark outline', BookmarkBorderIcon, 'BookmarkBorder'],
  Bookmarks: ['Bookmarks', BookmarksIcon, 'Bookmarks'],
  BookmarksOutline: [
    'Bookmarks outline',
    BookmarksOutlinedIcon,
    'BookmarksOutlined',
  ],
  Build: ['Build', BuildIcon, 'Build'],
  Calendar: ['Calendar', CalendarTodayIcon, 'CalendarToday'],
  Check: ['Check', CheckIcon, 'Check'],
  CheckFilled: ['CheckFilled', CheckCircleIcon, 'CheckCircle'],
  CityBlock: ['CityBlock', ApartmentIcon, 'Apartment'],
  Clock: ['Clock', AccessTimeOutlinedIcon, 'AccessTimeOutlined'],
  Close: ['Close', CloseIcon, 'Close'],
  CodeKey: ['CodeKey', VpnKeyIcon, 'VpnKey'],
  Collapse: ['Collapse', CollapseIcon, null],
  ColorLens: ['Color lens', ColorLensIcon, 'ColorLens'],
  Comment: ['Comment', CommentIcon, null],
  CompareArrows: ['Compare arrows', CompareArrowsIcon, 'CompareArrows'],
  Explore: ['Explore/Compass', ExploreIcon, 'Explore'],
  Copy: ['Copy', ContentCopyIcon, 'ContentCopy'],
  Delete: ['Delete', DeleteIcon, 'Delete'],
  DeleteOutline: ['Delete outline', DeleteOutlineIcon, 'DeleteOutline'],
  Document: ['Document', DescriptionIcon, 'Description'],
  DocumentOutline: [
    'Document outline',
    DescriptionOutlinedIcon,
    'DescriptionOutlined',
  ],
  DragIndicator: ['Drag indicator', DragIndicatorIcon, 'DragIndicator'],
  Edit: ['Edit', EditIcon, 'Edit'],
  Email: ['Email', EmailIcon, 'Email'],
  Error: ['Error', ErrorIcon, 'Error'],
  Event: ['Event', EventIcon, 'Event'],
  Exit: ['Exit', ExitToAppIcon, 'ExitToApp'],
  Expand: ['Expand', ExpandIcon, null],
  Eye: ['Eye', RemoveRedEyeIcon, 'RemoveRedEye'],
  EyeClosed: ['EyeClosed', VisibilityOffIcon, 'VisibilityOff'],
  FileDownload: ['File download', FileDownloadIcon, 'FileDownload'],
  Files: ['Files', TopicIcon, 'Topic'],
  FileUpload: ['File upload', FileUploadIcon, 'FileUpload'],
  Filter: ['Filter', FilterListIcon, 'FilterList'],
  Firm: ['Firm', CorporateFareIcon, 'CorporateFare'],
  FlagOutlined: ['FlagOutlined', FlagOutlined, 'FlagOutlined'],
  ForkedArrow: ['ForkedArrow', CallSplitIcon, 'CallSplit'],
  FormatAlignCenter: [
    'Format align center',
    FormatAlignCenterIcon,
    'FormatAlignCenter',
  ],
  FormatAlignLeft: [
    'Format align left',
    FormatAlignLeftIcon,
    'FormatAlignLeft',
  ],
  FormatAlignRight: [
    'Format align right',
    FormatAlignRightIcon,
    'FormatAlignRight',
  ],
  FormatBold: ['Format bold', FormatBoldIcon, 'FormatBold'],
  FormatColorText: [
    'Format color text',
    FormatColorTextIcon,
    'FormatColorText',
  ],
  FormatItalic: ['Format italic', FormatItalicIcon, 'FormatItalic'],
  FormatListBulleted: [
    'Format list bulleted',
    FormatListBulletedIcon,
    'FormatListBulleted',
  ],
  FormatListNumbered: [
    'Format list numbered',
    FormatListNumberedIcon,
    'FormatListNumbered',
  ],
  FormatUnderlined: [
    'Format underlined',
    FormatUnderlinedIcon,
    'FormatUnderlined',
  ],
  Groups: ['Groups', GroupsIcon, 'Groups'],
  HamburgerMenu: ['HamburgerMenu', MenuIcon, 'Menu'],
  Heart: ['Heart', FavoriteIcon, 'Favorite'],
  Home: ['Home', HomeIcon, 'Home'],
  Image: ['Image', ImageIcon, 'Image'],
  Info: ['Info', InfoIcon, 'Info'],
  InfoOutline: ['Info outline', InfoOutlinedIcon, 'InfoOutlined'],
  Instituition: ['Instituition', AccountBalanceIcon, 'AccountBalance'],
  KeyboardArrowDown: [
    'Keyboard arrow down',
    KeyboardArrowDownIcon,
    'KeyboardArrowDown',
  ],
  KeyboardArrowLeft: [
    'Keyboard arrow left',
    KeyboardArrowLeftIcon,
    'KeyboardArrowLeft',
  ],
  KeyboardArrowRight: [
    'Keyboard arrow right',
    KeyboardArrowRightIcon,
    'KeyboardArrowRight',
  ],
  KeyboardArrowUp: [
    'Keyboard arrow up',
    KeyboardArrowUpIcon,
    'KeyboardArrowUp',
  ],
  Launch: ['Launch', LaunchIcon, 'Launch'],
  Lightbulb: ['Lightbulb', LightbulbIcon, 'Lightbulb'],
  Link: ['Link', LinkIcon, 'Link'],
  LinkedIn: ['LinkedIn', LinkedIn, 'LinkedIn'],
  LinkOff: ['Link off', LinkOffIcon, 'LinkOff'],
  Lock: ['Lock', LockIcon, 'Lock'],
  Map: ['Map', MapIcon, 'Map'],
  More: ['More', MoreHorizIcon, 'MoreHoriz'],
  MoreVert: ['More vert', MoreVertIcon, 'MoreVert'],
  Notifications: ['Notifications', NotificationsIcon, 'Notifications'],
  Override: ['Override', ControlPointDuplicateIcon, 'ControlPointDuplicate'],
  PdfLibrary: ['PdfLibrary', PictureAsPdfIcon, 'PictureAsPdf'],
  Product: ['Product', LayersIcon, 'Layers'],
  PieChart: ['PieChart', PieChartIcon, 'PieChart'],
  Refresh: ['Refresh', RefreshIcon, 'Refresh'],
  Remove: ['Remove', RemoveIcon, 'Remove'],
  Search: ['Search', SearchIcon, 'Search'],
  Settings: ['Settings', SettingsIcon, 'Settings'],
  Share: ['Share', ShareIcon, 'Share'],
  Shield: ['Shield', GppGoodIcon, 'GppGood'],
  ShoppingBasket: [
    'ShoppingBasketIcon',
    ShoppingBasketIcon,
    'ShoppingBasketIcon',
  ],
  SortByAlpha: ['SortByAlpha', SortByAlphaIcon, null],
  SortByAlphaReverse: ['SortByAlphaReverse', SortByAlphaReverseIcon, null],
  SortByDate: ['SortByDate', SortByDateIcon, null],
  SortByDateReverse: ['SortByDateReverse', SortByDateReverseIcon, null],
  SortHighToLow: ['SortHighToLow', SortIcon, 'Sort'],
  SortLowToHigh: ['SortLowToHigh', SortLowToHighIcon, null],
  SubdirectoryArrowRight: [
    'SubdirectoryArrowRight',
    SubdirectoryArrowRightIcon,
    'SubdirectoryArrowRight',
  ],
  Sync: ['Sync', SyncIcon, 'Sync'],
  SyncDisabled: ['SyncDisabled', SyncDisabledIcon, 'SyncDisabled'],
  Target: ['Target', TrackChangesIcon, 'TrackChanges'],
  Teams: ['Teams', PeopleAltIcon, 'PeopleAlt'],
  User: ['User', PersonIcon, 'Person'],
  Video: ['Video', VideoLibraryIcon, 'VideoLibrary'],
  ViewList: ['View list', ViewListIcon, 'ViewList'],
  Wallet: ['Wallet', AccountBalanceWalletIcon, 'AccountBalanceWallet'],
  Warning: ['Warning', WarningIcon, 'Warning'],
  ZoomIn: ['ZoomIn', ZoomInIcon, 'ZoomIn'],
  ZoomOut: ['ZoomOut', ZoomOutIcon, 'ZoomOut'],
}
