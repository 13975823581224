import {
  ContentPermissionsData,
  parseFromCollection,
} from '@cais-group/shared/domain/contentful/api'
import { filterAllowedPermissionData } from '@cais-group/shared/domain/contentful/content-permissions'
import type {
  EventType,
  PressReleaseType,
} from '@cais-group/shared/util/contentful/types'
import {
  GetAllResearchQuery,
  GetTutorialsPageContentQuery,
  GetVideoPlaylistsQuery,
  CaisiqVideoPlaylist,
  GetVideosQuery,
  CaisiqVideo,
  GetWhitepaperCollectionQuery,
  GetArticlesQuery,
  GetWebinarsQuery,
  GetExternalContentQuery,
  GetEventsQuery,
  GetPressReleasesQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { ContentDataType } from '../types'

export function selectResearch(
  allowedPermissionsData: ContentPermissionsData,
  data?: GetAllResearchQuery
) {
  return filterAllowedPermissionData<ContentDataType>(
    [
      ...parseFromCollection<GetAllResearchQuery, ContentDataType>(
        data,
        'caisiqArticleCollection'
      ),
      ...parseFromCollection<GetVideosQuery, CaisiqVideo>(
        data,
        'caisiqVideoCollection'
      ),
      ...parseFromCollection<GetVideoPlaylistsQuery, CaisiqVideoPlaylist>(
        data,
        'caisiqVideoPlaylistCollection'
      ),
      ...parseFromCollection<GetWhitepaperCollectionQuery, WhitepaperData>(
        data,
        'whitepaperCollection'
      ),
      ...parseFromCollection<GetExternalContentQuery, ContentDataType>(
        data,
        'externalContentCollection'
      ),
    ],
    allowedPermissionsData
  )
}

export function selectTutorials(
  allowedPermissionsData: ContentPermissionsData,
  data?: GetTutorialsPageContentQuery
) {
  return filterAllowedPermissionData<ContentDataType>(
    [
      ...parseFromCollection<GetArticlesQuery, ContentDataType>(
        data,
        'caisiqArticleCollection'
      ),
      ...parseFromCollection<GetVideosQuery, CaisiqVideo>(
        data,
        'caisiqVideoCollection'
      ),
      ...parseFromCollection<GetVideoPlaylistsQuery, CaisiqVideoPlaylist>(
        data,
        'caisiqVideoPlaylistCollection'
      ),
      ...parseFromCollection<GetWhitepaperCollectionQuery, WhitepaperData>(
        data,
        'whitepaperCollection'
      ),
    ],
    allowedPermissionsData
  )
}

export function selectUpcomingWebinars(
  allowedPermissionsData: ContentPermissionsData,
  data?: GetWebinarsQuery
) {
  return filterAllowedPermissionData<EventType>(
    (data?.upcoming?.items as EventType[]) ?? [],
    allowedPermissionsData
  )
}

export function selectReplayWebinars(
  allowedPermissionsData: ContentPermissionsData,
  data?: GetWebinarsQuery
) {
  const replays = [
    ...parseFromCollection<GetVideosQuery, CaisiqVideo>(
      data,
      'caisiqVideoCollection'
    ),
    ...parseFromCollection<GetVideoPlaylistsQuery, CaisiqVideoPlaylist>(
      data,
      'caisiqVideoPlaylistCollection'
    ),
  ]

  return filterAllowedPermissionData<CaisiqVideo | CaisiqVideoPlaylist>(
    replays ?? [],
    allowedPermissionsData
  )
}

export function selectPressReleases(data?: GetPressReleasesQuery) {
  return parseFromCollection<GetPressReleasesQuery, PressReleaseType>(
    data,
    'pressReleases'
  )
}

export function selectEvents(
  allowedPermissionsData: ContentPermissionsData,
  data?: GetEventsQuery
) {
  const events = parseFromCollection<GetEventsQuery, EventType>(
    data,
    'eventCollection'
  )

  return [
    ...filterAllowedPermissionData<EventType>(
      events ?? [],
      allowedPermissionsData
    ),
  ]
}

export function selectProducts(
  allowedPermissionsData: ContentPermissionsData,
  data?: GetExternalContentQuery
) {
  const products = parseFromCollection<
    GetExternalContentQuery,
    ContentDataType
  >(data, 'externalContentCollection')

  return [
    ...filterAllowedPermissionData<ContentDataType>(
      products ?? [],
      allowedPermissionsData
    ),
  ]
}

type CarouselContentType<K> = {
  filter: (
    allowedPermissionsData: ContentPermissionsData,
    data?: K
  ) => ContentDataType[]
  extract: (
    data: ContentDataType[],
    featuredItemId: string | undefined
  ) => ContentDataType[]
  data?: K
}

export function prepareCarouselContent(
  allowedPermissionsData: ContentPermissionsData,
  featuredItemId: string | undefined
) {
  return function carouselContent<K>(props: CarouselContentType<K>) {
    const { filter, extract, data } = props
    const filteredItems = filter(allowedPermissionsData, data)
    return extract(filteredItems, featuredItemId)
  }
}
