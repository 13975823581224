import type { ClientError } from 'graphql-request'

import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  ContentPermissionsData,
} from '@cais-group/shared/domain/contentful/api'
import { useGetAllowedContentPermissions } from '@cais-group/shared/domain/contentful/content-permissions'
import {
  CAROUSEL_ITEM_LIMIT,
  FEATURED_CONTENT_APP_ID,
  FIRMS_COLLECTION_LIMIT,
  PRESS_RELEASE_INDEX,
  TAGS_COLLECTION_LIMIT,
} from '@cais-group/shared/util/constants'
import {
  getPwsDomain,
  sortContentData,
} from '@cais-group/shared/util/contentful/content-helpers'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import type {
  ContentDataType,
  CuratedContentDataType,
  HomeContentType,
  PressReleaseType,
} from '@cais-group/shared/util/contentful/types'
import {
  useGetFeaturedHomeQuery,
  useGetWebinarsQuery,
  useGetEventsQuery,
  useGetTutorialsPageContentQuery,
  useGetAllResearchQuery,
  useGetPressReleasesQuery,
  GetExternalContentQuery,
  useGetExternalContentQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import type {
  GetAllResearchQuery,
  GetEventsQuery,
  GetFeaturedHomeQuery,
  GetTutorialsPageContentQuery,
  GetWebinarsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { logWarning } from '@cais-group/shared/util/logging'

import {
  selectFirmCuratedContent,
  useGetFirmCuratedContent,
} from '../firm/use-get-firm-curated-content'
import { selectFeaturedItem } from '../helper'
import {
  excludeFeaturedItemAndExtract,
  excludeFeaturedItemSortAndExtract,
} from '../helper/exclude-featured-item-sort-and-extract'
import { makeHomeQueryParams } from '../helper/make-home-query-params'
import {
  selectEvents,
  selectProducts,
  selectResearch,
  selectTutorials,
  selectUpcomingWebinars,
  selectReplayWebinars,
  prepareCarouselContent,
  selectPressReleases,
} from '../helper/select-content-helpers'
import { selectLatestContent } from '../helper/select-latest-content'

type PageData = {
  featured: ContentDataType | null
  press: {
    pressIndexLink: string
    pressReleases: PressReleaseType[]
    error: boolean
  }
  content: HomeContentType & {
    loading: boolean
    error: boolean
  }
  curatedContent: {
    data: CuratedContentDataType[] | null
    loading: boolean
    error: boolean
  }
}

type UseGetHomeProps = {
  options: {
    pressReleasesEnabled: boolean
  }
}
export const useGetHome = ({ options }: UseGetHomeProps) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()
  const response = useReactQueryResultAsApiState<
    GetFeaturedHomeQuery,
    ContentDataType | null
  >(
    useGetFeaturedHomeQuery(
      {
        featuredId: FEATURED_CONTENT_APP_ID,
        preview: previewService.enabled,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
        tagsLimit: TAGS_COLLECTION_LIMIT,
      },
      {
        refetchOnWindowFocus: false,
        enabled: !allowedUserPermissions.isLoading,
      }
    ),
    (data) =>
      selectFeaturedItem<ContentDataType>({
        options: data?.featured?.items[0]?.options?.items as ContentDataType[],
        fallback: data?.featured?.items[0]?.fallback as ContentDataType,
        userAccessData: allowedUserPermissions.data,
      }),
    'Could not load home query'
  )

  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  const featuredItemId = isData(response) ? response?.sys.id : undefined

  const { press, content, curatedContent } = useSelectHome({
    allowedPermissionsData: allowedUserPermissions.data,
    featuredItemId,
    options,
    waitForPermissions: allowedUserPermissions.isLoading,
  })
  const featured = isData(response) ? response : null

  return compileResults(
    {
      featured,
      press,
      content,
      curatedContent,
    },
    loading,
    error
  )
}

type SelectHomeProps = {
  allowedPermissionsData: Omit<ContentPermissionsData, 'notFound'>
  featuredItemId?: string
  waitForPermissions: boolean
} & UseGetHomeProps
const useSelectHome = ({
  allowedPermissionsData,
  featuredItemId,
  options,
  waitForPermissions,
}: SelectHomeProps) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: (failureCount: number) => failureCount < 1,
    onError: (error: ClientError) => {
      logWarning({ error, message: error.message })
    },
    enabled: Boolean(allowedPermissionsData),
  }

  const {
    externalContentQueryParams,
    inPersonEventQueryParams,
    pressReleasesQueryParams,
    researchQueryParams,
    tutorialQueryParams,
    webinarQueryParams,
  } = makeHomeQueryParams()

  const curatedContent = useGetFirmCuratedContent()

  // TODO - do we want to logErrors individually on each query?
  const pressReleases = useGetPressReleasesQuery(pressReleasesQueryParams, {
    ...queryOptions,
    enabled: options.pressReleasesEnabled,
  })

  const externalContent = useGetExternalContentQuery(
    externalContentQueryParams,
    queryOptions
  )

  const webinars = useGetWebinarsQuery(webinarQueryParams, queryOptions)
  const events = useGetEventsQuery(inPersonEventQueryParams, queryOptions)
  const tutorials = useGetTutorialsPageContentQuery(
    tutorialQueryParams,
    queryOptions
  )
  const research = useGetAllResearchQuery(researchQueryParams, queryOptions)

  const carouselContent = prepareCarouselContent(
    allowedPermissionsData,
    featuredItemId
  )

  const webinarsCarousel = {
    upcoming: carouselContent<GetWebinarsQuery>({
      filter: selectUpcomingWebinars,
      extract: excludeFeaturedItemAndExtract,
      data: webinars.data,
    }),
    replays: carouselContent<GetWebinarsQuery>({
      filter: selectReplayWebinars,
      extract: excludeFeaturedItemSortAndExtract,
      data: webinars.data,
    }),
  }
  const externalCarousel = carouselContent<GetExternalContentQuery>({
    filter: selectProducts,
    extract: excludeFeaturedItemSortAndExtract,
    data: externalContent.data,
  })

  const researchCarousel = carouselContent<GetAllResearchQuery>({
    filter: selectResearch,
    extract: excludeFeaturedItemSortAndExtract,
    data: research.data,
  })

  const tutorialsCarousel = carouselContent<GetTutorialsPageContentQuery>({
    filter: selectTutorials,
    extract: excludeFeaturedItemSortAndExtract,
    data: tutorials.data,
  })

  const eventsCarousel = carouselContent<GetEventsQuery>({
    filter: selectEvents,
    extract: excludeFeaturedItemAndExtract,
    data: events.data,
  })

  const content = {
    products: {
      carousel: externalCarousel.slice(0, CAROUSEL_ITEM_LIMIT),
      error: externalContent.isError,
    },
    webinars: {
      carousel: [
        ...webinarsCarousel.upcoming,
        ...webinarsCarousel.replays,
      ].slice(0, CAROUSEL_ITEM_LIMIT),
      error: webinars.isError,
    },
    research: {
      carousel: researchCarousel.slice(0, CAROUSEL_ITEM_LIMIT),
      error: research.isError,
    },
    tutorials: {
      carousel: tutorialsCarousel.slice(0, CAROUSEL_ITEM_LIMIT),
      error: tutorials.isError,
    },
    events: {
      carousel: eventsCarousel.slice(0, CAROUSEL_ITEM_LIMIT),
      error: events.isError,
    },
  }

  const draftLatestContent = {
    products: {
      carousel: externalCarousel,
    },
    webinars: {
      carousel: [...webinarsCarousel.upcoming, ...webinarsCarousel.replays],
    },
    research: {
      carousel: researchCarousel,
    },
    tutorials: {
      carousel: tutorialsCarousel,
    },
    events: {
      carousel: eventsCarousel,
    },
  }
  // For the "Latest" tab combine the upcoming Events and Webinars
  // If there are not enough upcoming items, show the webinar replays
  const isEventsArray = Array.isArray(draftLatestContent.events.carousel)
  const isWebinarsArray = Array.isArray(draftLatestContent.webinars.carousel)
  let combinedEventsAndWebinars = []

  if (isEventsArray && isWebinarsArray) {
    combinedEventsAndWebinars = sortContentData(
      [...draftLatestContent.events.carousel, ...webinarsCarousel.upcoming],
      { order: 'asc' }
    )
  } else if (isEventsArray) {
    combinedEventsAndWebinars = draftLatestContent.events.carousel
  } else {
    combinedEventsAndWebinars = draftLatestContent.webinars.carousel ?? []
  }
  const combinedError =
    externalContent.isError &&
    webinars.isError &&
    events.isError &&
    tutorials.isError &&
    research.isError

  const combinedLoading =
    events.isLoading ||
    externalContent.isLoading ||
    research.isLoading ||
    tutorials.isLoading ||
    webinars.isLoading ||
    waitForPermissions

  const wait = combinedLoading || combinedError
  // Latest cards are selected from each content type and not sorted like the rest
  const { latestByFirm, latestByCais } = selectLatestContent(
    [
      draftLatestContent.products.carousel,
      combinedEventsAndWebinars,
      draftLatestContent.research.carousel,
      draftLatestContent.tutorials.carousel,
    ],
    wait
  )

  return {
    press: {
      pressIndexLink: `${getPwsDomain()}${PRESS_RELEASE_INDEX}`,
      pressReleases: selectPressReleases(pressReleases.data).slice(
        0,
        CAROUSEL_ITEM_LIMIT
      ),
      error: pressReleases.isError,
    },
    curatedContent: {
      data: selectFirmCuratedContent(
        curatedContent.data,
        allowedPermissionsData
      ),
      loading: curatedContent.isLoading,
      error: curatedContent.isError,
    },
    content: {
      ...content,
      latestByFirm: {
        carousel: latestByFirm,
        error: combinedError,
      },
      latest: {
        carousel: latestByCais,
        error: combinedError,
      },
      error: options.pressReleasesEnabled
        ? combinedError && pressReleases.isError
        : combinedError,
      loading: combinedLoading || pressReleases.isLoading,
    },
  }
}

const compileResults = (
  data: PageData | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}
