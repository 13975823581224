import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ReactElement } from 'react'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { authService } from '@cais-group/shared/util/auth-service'
import { logAction } from '@cais-group/shared/util/logging'

const PassThrough = ({ children }: { children: ReactElement }) => children

/**
 * Redirects logged out users attempting to access this route to
 * the login page
 */
export const AuthProtected = withAuthenticationRequired(PassThrough, {
  onRedirecting: () => (
    <LoadingContainer
      state={LoadingState.LOADING}
      coverPage="FULL_SCREEN"
      type="large"
    ></LoadingContainer>
  ),
  onBeforeAuthentication: async () => {
    authService.setAuthReturnTo()
    const [, appBasePath] = window.location.pathname.split('/')
    logAction({
      message: 'User is not authenticated, redirecting to login',
      context: { appBasePath },
    })
  },
  // This gets passed to the onRedirectCallback of Auth0Provider as
  // appState.returnTo
  returnTo: () => window.location.pathname + window.location.search,
})
