export type FeatureFlagsConfig = {
  className: string
  dataAttrs: Record<`data-feature-${string}`, boolean>
}

/**
 * Generates the feature flags as data attributes and associated CSS in a configuration object.
 *
 * IMPORTANT: after these features are the new baseline (after Summit) this approach will be deprecated in preference of the new feature flag system.
 *
 * 1. Create feature flag - see note in [ReadMe](https://github.com/cais-group/monorepo/blob/main/libs/shared/ui/main-nav/src/lib/README.md#feature-flags)
 * 2. Create CSS to show/hide the options as noted below
 * 3. Add the CSS to the return object
 *
 * CSS: :not([data-FEATURE-FLAG]) li[data-NEW-MENU]:hidden // show the new menu if the feature flag is enabled, otherwise hide
 * CSS: li:has(button[data-NEW-MENU]) // selector when it's an option with a menu
 * CSS: li:[data-NEW-MENU] // selector when it's a top-level link i.e there's no menu
 */

export function featureFlagsConfig(
  enabledFeatureFlags: string[]
): FeatureFlagsConfig {
  const dataAttrs = enabledFeatureFlags.reduce((flags, flag) => {
    flags[`data-feature-${flag}`] = true
    return flags
  }, {} as Record<`data-feature-${string}`, boolean>)

  const investments =
    '[&:not([data-feature-investments-menu])_:is(ul>div,li):has(button[data-menu-id="investments-menu"])]:hidden [&[data-feature-investments-menu]_:is(ul>div,li):has(button[data-menu-id="offerings-menu"])]:hidden'
  const modelPortfolios =
    '[&:not([data-feature-model-portfolios])_:is(a,li)[data-link-id="model-portfolios"]]:hidden'

  return {
    className: `${investments} ${modelPortfolios}`,
    dataAttrs,
  }
}
